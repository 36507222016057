<template>
    <g>
        <path
            d="M48.8484 44.2398L35.6238 30.9485C41.9021 22.1321 39.8316 9.90946 31.0153 3.63115C22.1989 -2.64716 9.90946 -0.57665 3.63115 8.2397C-2.64716 17.0561 -0.57665 29.3455 8.2397 35.557C15.0523 40.366 24.1358 40.366 30.9485 35.557L44.2398 48.8484C45.442 50.251 47.5125 50.3845 48.8484 49.1823C50.251 47.9801 50.3845 45.9096 49.1823 44.5738C49.1155 44.4402 48.9819 44.3066 48.8484 44.2398ZM30.6145 30.5477C24.5366 36.5589 14.7852 36.5589 8.70724 30.5477C2.6293 24.5366 2.69609 14.7184 8.70724 8.64044C14.7184 2.6293 24.5366 2.6293 30.5477 8.64044C36.6257 14.7184 36.6257 24.5366 30.6145 30.5477Z"
        />
        <path
            d="M11 20C11 18.8954 11.8954 18 13 18H27C28.1046 18 29 18.8954 29 20C29 21.1046 28.1046 22 27 22H13C11.8954 22 11 21.1046 11 20Z"
        />
    </g>
</template>

<script>
    export default {
        name: 'IconZoomOut',
    };
</script>

<style scoped></style>
